<template>
  <label>
    <span class="form-label">{{ label }}</span>
    <textarea
      :value="modelValue"
      class="form-control"
      :class="{
        'is-invalid': isInvalid
      }"
      @input="onInput"
    />
    <div class="d-flex justify-content-between">
      <span
        class="invalid-feedback me-10"
        :class="{
          'd-block': isInvalid
        }"
      >
        {{ errorMessage }}
      </span>
      <span class="ms-auto">
        {{ note }}
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
//-----PROPS-----\\
defineProps({
  modelValue: { type: String, default: undefined },
  label: { type: String, default: undefined },
  note: { type: String, default: undefined },
  errorMessage: { type: String, default: undefined },
  isInvalid: { type: Boolean },
});
const emits = defineEmits(['update:modelValue']);

//-----VARIABLES-----\\

//-----STATE-----\\

//-----COMPUTED-----\\

//-----ASYNC-----\\

//-----METHODS-----\\
function onInput(e) {
  emits('update:modelValue', e.target.value);
}
</script>

<style scoped lang="scss">

</style>